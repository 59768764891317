<template>
  <section class="section is-medium">
    <div class="container">
      <div class="columns is-vcentered is-centered">
        <div class="column has-text-centered">
          <h1 class="title">404. Страница не найдена</h1>
          <p class="subtitle mt-4">Пожалуйста, свяжитесь с администратором</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
};
</script>
